import ChineseFood from '../../../Assets/ChineseFood.png';
import Shrimp from '../../../Assets/Shrimp.png';
import Takeout from '../../../Assets/Takeout.png';
import Dinner from '../../../Assets/Dinner.png';




export const SliderData = [
    {
        image: ChineseFood
    },
    {
        image: Shrimp
    },
    {
        image: Takeout
    },
    {
        image: Dinner
    }
]